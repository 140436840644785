export const AUTH_TOKEN_NAME = 'tantum_auth_token';
export const PREVIOUS_AUTH_TOKEN_NAME = 'tantum_previous_auth_token';
export const LAST_FULL_PATH = 'tantum_last_path';
export const API_URL = process.env.VUE_APP_API_URL || 'http://localhost:3016/api/v1'
export const WS_API_URL = API_URL.replace(/^http(s)?/, 'ws$1') + '/cable'

export const DEFAULT_VALUES = {
    COUNTRY_CODE: 'DE',
}

export const SPECIAL_ROLES = {
    taxOffice: 'tax_office'
}
export const INVOICE_TYPES = ['hours', 'pieces', 'wholesale']

export const PROVIDERS = {
    manet: 'Manet',
}
export const CLIENT_TAB_TYPES = {
    HISTORY: 'history',
    OVERVIEW: 'overview',
    CONTRACTS: 'contracts',
    CONTACTS: 'contacts',
    PRODUCTS: 'products',
    PRODUCT_BILLING_PERIODS: 'products_billing_periods',
    INVOICES: 'invoices',
    DIRECT_DEBIT_MANDATES: 'direct_debit_mandates',
}

export const NANO_EVENT_TYPES = {
    CLIENT_CLICKED: 'client_clicked',
    CLIENT_EVENT_CHANGED: 'client_event_changed',
    CONTRACT_CHANGED: 'contract_changed',
    PRODUCT_BILLING_PERIOD_CHANGED: 'product_billing_period_changed',
    PRODUCT_CHANGED: 'product_changed',
}

export const CLIENT_EVENT_TYPES = {
    NOTE: 'note',
    VISIT: 'visit',
    CALL: 'call',
    EMAIL: 'email',
    ORDER_PLACED: 'order_placed',
    RENEWAL: 'renewal',
    INVOICE_REMINDER1_SENT: 'invoice_reminder1_sent',
    INVOICE_REMINDER2_SENT: 'invoice_reminder2_sent',
    PAYMENT_RECEIVED: 'payment_received',
    INVOICE_SENT: 'invoice_sent',
    INVOICE_CANCELED: 'invoice_canceled',
    INVOICE_OUTSOURCED: 'invoice_outsourced',
    TERMINATION_RECEIVED: 'termination_received',
    TERMINATION_ACKNOWLEDGED: 'termination_acknowledged',
    CONSULTANT_CHANGED: 'consultant_changed',
}

export const CLIENT_EVENT_TYPES_CREATE = [
    CLIENT_EVENT_TYPES.NOTE,
    CLIENT_EVENT_TYPES.CALL,
    CLIENT_EVENT_TYPES.EMAIL,
    CLIENT_EVENT_TYPES.VISIT,
    CLIENT_EVENT_TYPES.ORDER_PLACED,
    CLIENT_EVENT_TYPES.TERMINATION_RECEIVED,
]

export const CLIENT_EVENT_TYPES_APPEND = [
    CLIENT_EVENT_TYPES.NOTE,
    CLIENT_EVENT_TYPES.CALL,
    CLIENT_EVENT_TYPES.EMAIL,
    CLIENT_EVENT_TYPES.VISIT,
    // CLIENT_EVENT_TYPES.ORDER_PLACED,
]

export const CLIENT_EVENT_TYPES_APPEND_INVOICE = [
    CLIENT_EVENT_TYPES.NOTE,
    CLIENT_EVENT_TYPES.CALL,
    CLIENT_EVENT_TYPES.EMAIL,
    CLIENT_EVENT_TYPES.VISIT,
    CLIENT_EVENT_TYPES.INVOICE_CANCELED,
]



export const CLIENT_EVENTABLE_TYPES = {
    CONTRACT: 'Contract',
    PRODUCT: 'Product',
    INVOICE: 'Invoice',
}

export const PERMISSIONS = {
    ADMIN: {
        ADMIN: "admin"
    },
    CLIENTS: {
        CREATE: "clients.create",
        EDIT: "clients.edit",
        VIEW: "clients.view",
        DELETE: "clients.delete",
    },
    CLIENT_EVENTS: {
        CREATE: "client_events.create",
        EDIT: "client_events.edit",
        VIEW: "client_events.view",
        DELETE: "client_events.delete",
        ADMIN: "client_events.admin",
    },
    CONTACTS: {
        CREATE: "contacts.create",
        EDIT: "contacts.edit",
        VIEW: "contacts.view",
        DELETE: "contacts.delete",
    },
    CONTRACTS: {
        CREATE: "contracts.create",
        EDIT: "contracts.edit",
        VIEW: "contracts.view",
        DELETE: "contracts.delete",
    },
    PRODUCTS: {
        CREATE: "products.create",
        EDIT: "products.edit",
        VIEW: "products.view",
        DELETE: "products.delete",
        ADMIN: "products.admin",
    },
    INVOICES: {
        CREATE: "invoices.create",
        EDIT: "invoices.edit",
        VIEW: "invoices.view",
        SEND: "invoices.send",
        DELETE: "invoices.delete",
    },
    RECEIPTS: {
        CREATE: "receipts.create",
        EDIT: "receipts.edit",
        VIEW: "receipts.view",
        DELETE: "receipts.delete",
    },
    TRANSACTIONS: {
        VIEW: "transactions.view",
    },
    ACCOUNTS: {
        CREATE: "accounts.create",
        EDIT: "accounts.edit",
        VIEW: "accounts.view",
        DELETE: "accounts.delete",
    },
    USERS: {
        CREATE: "users.create",
        EDIT: "users.edit",
        VIEW: "users.view",
        DELETE: "users.delete",
    },
    ORGANIZATIONS: {
        CREATE: "organizations.create",
        EDIT: "organizations.edit",
        VIEW: "organizations.view",
        DELETE: "organizations.delete",
    },
}

export const PERMISSIONS_PRESETS = {
    admin: [PERMISSIONS.ADMIN.ADMIN],
    sales: [
        PERMISSIONS.CLIENTS.CREATE,
        PERMISSIONS.CLIENTS.VIEW,
        PERMISSIONS.CLIENTS.EDIT,
        PERMISSIONS.CONTACTS.CREATE,
        PERMISSIONS.CONTACTS.VIEW,
        PERMISSIONS.CONTACTS.EDIT,
        PERMISSIONS.PRODUCTS.CREATE,
        PERMISSIONS.PRODUCTS.VIEW,
        PERMISSIONS.PRODUCTS.EDIT,
        PERMISSIONS.CLIENT_EVENTS.CREATE,
        PERMISSIONS.CLIENT_EVENTS.VIEW,
        PERMISSIONS.CLIENT_EVENTS.EDIT,
        PERMISSIONS.INVOICES.CREATE,
        PERMISSIONS.INVOICES.VIEW,
        PERMISSIONS.INVOICES.EDIT,
        PERMISSIONS.CONTRACTS.CREATE,
        PERMISSIONS.CONTRACTS.VIEW,
        PERMISSIONS.CONTRACTS.EDIT,
        PERMISSIONS.USERS.VIEW,
    ],
    tax_office: [
        PERMISSIONS.ACCOUNTS.VIEW,
        PERMISSIONS.INVOICES.VIEW,
        PERMISSIONS.TRANSACTIONS.VIEW
    ]
}

export const PRODUCT_BILLING_RENEWAL_SETTINGS = {
    NO_RENEWAL: 'no_renewal',
}

export const PRODUCT_BILLING_DURATIONS = {
    NONE: 'none',
}

export const PRODUCT_PAYMENT_CYCLE = {
    NONE: 'none',
    ONCE: 'once',
}

export const INVOICE_STATUS = {
    DRAFT: 'draft',
    FIXED: 'fixed',
    SENT: 'invoice_sent',
    RESEND: 'resend',
    REMINDED1: 'invoice_reminded1',
    REMINDED2: 'invoice_reminded2',
    OUTSOURCED: 'outsourced',
    PAID: 'paid',
    CANCELED: 'canceled',
    CLEARED: 'cleared',
}
export const ANALYTICS_CHART_TYPES = {
    BAR: 'bar',
    BAR_MIRRORED: 'bar-mirrored',
    LINE: 'line',
    LINE_MIRRORED: 'line-mirrored',
}
export const ROUTES = {
    ACCOUNTS: {
        INDEX: 'accounts.index',
        SHOW: 'accounts.show',
        CREATE: 'accounts.create',
        EDIT: 'accounts.edit',
        TAGS: 'accounts.tags',
        IMPORT_TRANSACTIONS: 'accounts.import_transactions',
        ANALYTICS: 'accounts.analytics',
        CALLBACK: 'accounts.callback',
    },
    CLIENTS: {
        INDEX: 'clients.index',
        SHOW: 'clients.show',
        CREATE: 'clients.create',
        EDIT: 'clients.edit',
    },
    CLIENT_EVENTS: {
        INDEX: 'client_events.index',
        SHOW: 'client_events.show',
        EDIT: 'client_events.edit',
    },
    CONFIRM: 'confirm',
    CONTRACTS: {
        INDEX: 'contracts.index',
        SHOW: 'contracts.show',
        CREATE: 'contracts.create',
        EDIT: 'contracts.edit',
    },
    CONTACTS: {
        INDEX: 'contacts.index',
        SHOW: 'contacts.show',
        EDIT: 'contacts.edit',
    },
    DASHBOARD: 'dashboard',
    DIRECT_DEBIT_MANDATES: {
        INDEX: 'direct_debit_mandates.index',
        CREATE: 'direct_debit_mandates.create',
        EDIT: 'direct_debit_mandates.edit',
    },
    DUNNING: {
        INDEX: 'dunning.index',
        EDIT: 'dunning.edit',
    },
    INVOICES: {
        INDEX: 'invoices.index',
        CREATE: 'invoices.create',
        EDIT: 'invoices.edit',
    },
    LOGIN: 'login',
    ORGANIZATIONS: {
        INDEX: 'organizations.index',
        SHOW: 'organizations.show',
        CREATE: 'organizations.create',
    },
    PRODUCTS: {
        INDEX: 'products.index',
        SHOW: 'products.show',
        CREATE: 'products.create',
        EDIT: 'products.edit',
    },
    PRODUCT_BILLING_PERIODS: {
        INDEX: 'product_billing_periods.index',
        EDIT: 'product_billing_periods.edit',
    },
    PRODUCT_TYPES: {
      INDEX:   'product_types.index',
    },
    RECEIPTS: {
        INDEX: 'receipts.index',
        CREATE: 'receipts.create',
    },
    REGISTER: 'register',
    USERS: {
        INDEX: 'users.index',
        EDIT: 'users.edit',
    }
}